import React, {useEffect, useState, useRef} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {deleteCardById, deleteFileAttachment, updateCardById} from '../../services/cards'
import {awsFileUploadConfig} from '../../config/awsFileUpload'
import {
  useBoardPersons,
  useFileUploader,
  // useGetAllBoards,
  useGetColumns,
  useWebSocketHook,
} from '../../hooks'
import {setIsCardUpdated} from '../../store/slice/last-update-slice'
import {getTimeDateFormate} from '../../helper-functions/TimeFormate'
import UpdateCardForm from '../Forms/UpdateCardForm'
import ReactS3Client from 'react-aws-s3-typescript'
import useSendAlert from '../../hooks/useSendAlert'
import * as Yup from 'yup'
import {setIsRender} from '../../store/slice/isRender-slice'
import {webSocketOperations} from '../../constants'
import {capitalizeEachWord} from '../../helper-functions/CapitalizeName'
import {longWordSlicing} from '../../helper-functions/CheckString'
// import {fetchAllCardByBoardId, fetchAllCardsofLoggedInUser} from '../../store/slice/card-slice'
// import EditCardSkeleton from '../../components/Modals/EditCardSkeleton'
import DropdownClone from '../../components/CloneCardDropdown'
import {removeNullFromReminder} from '../../helper-functions/RemoveNullFromReminders'
import '../../styles/updateanaddform.css'

import CloneCardModal from '../Modals/CloneCardModal'
import DeleteCardModal from '../Modals/DeleteCardModal'
import { useTheme } from '../../context/ThemeContext'
import useGetDescription from '../../hooks/useGetCardDescription'
import useGetTags from '../../hooks/useGetTags'
import { addBoardTags, removeBoardTags } from '../../store/slice/board-tags-slice'
import { applyColumnRules } from '../../utils/applyColumnRules'
import { autoCloneCard } from '../../utils/autoCloneCard'
import { fetchAllCardsofLoggedInUser } from '../../store/slice/card-slice'

const cardSchema = Yup.object().shape({
  cardName: Yup.string()
    .min(3, 'Minimum 3 Characters')
    .max(300, 'Maximum 300 Characters')
    .required('Task Name is Required')
    .trim(),

  columnName: Yup.string().required('Column is Required'),
  boardName: Yup.string().required('Board is Required'),
  status: Yup.string().required('Status is Required'),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EditNewCard = ({
  isBoard,toClone,
  cardDetails,
  cardModalShow,
  setCardModalShow,
  loading,
  handleCloneModalClose,
  cloneModalShow,
  cloneCardHandler,
  setToClone,
  handleCloneModalShow,
  isHomePage,
  setCards,allCards,cards,
  isLastUpdate
}: any): React.JSX.Element => {
  // const location = useLocation()
  const {cardDescription ,setCardDescription,isCardDescriptionLoading,cardIdForDesc} = useGetDescription(cardDetails?.id)
  const [disableBtn, setDisableBtn] = useState(false)
  const [disableDeleteCardBtn, setDisableDeleteCardBtn] = useState(false)
  const [showCardDelete, setShowCardDelete] = useState(false)
  const [deleteCardMsg, setDeleteCardMsg] = useState('')
  const handleClose =()=> setShowCardDelete(false)
  const handleShow =()=> setShowCardDelete(true)

  // const [editorDescriptionState, setEditorDescriptionState] = useState('')
  const [checkIsDescriptionExist] = useState(
     cardDescription ? true : false
  )
  const { tags, loading: tagsLoading } = useGetTags() 
  const transformedTags = (cardDetails?.tags || []).map((tag: any) => ({
    value: tag.id,
    label: tag.name
  }));
  const [selectedTags, setSelectedTags] = useState(transformedTags)
  const [tagsToUpdate, setTagsToUpdate] = useState<any>({
    addedTags: [],
    removedTags: [],
  }); 

  const [boardId, setBoardId] = useState(cardDetails?.boardId)
  const [columnId, setColumnId] = useState(cardDetails?.board_column_id)
  const [cardLength, setCardLength] = useState<number>()
  const [startDate, setStartDate] = useState<any>(
    cardDetails?.due_date && cardDetails?.due_time
      ? new Date(`${cardDetails?.due_date}T${cardDetails?.due_time}Z`)
      : ''
  )
  const [editorComment, setEditorComment] = useState('')
  const isLastUpdated: any = useSelector((state: any) => state.lastUpdateReducer)
  const [reminders, setReminders] = useState<any>([])
  const [deleteReminderIds, setDeletedReminderIds] = useState<any>([])
  const {isCardUpdated} = isLastUpdated.isCardUpdated

  const dispatch: Dispatch<any> = useDispatch()
  const s3 = new ReactS3Client({
    ...awsFileUploadConfig,
    dirName: `cards-attachments/${process.env.REACT_APP_ENVIRONMENT}`,
  })
  const {allBoards,isBoardLoading} = useSelector((state: any) => state.allBoardsReducer)
  const childCommentRef: any = useRef()
  const params: any = useParams()
  const {currentUser}: any = useAuth()
  const navigate = useNavigate()
  const {id, fullName} = currentUser?.data.user
  // const boardFromReducer = useSelector((state: any) => state.boardReducer)
  // const { data: boards } = boardFromReducer?.entities

  // const {allBoards, isBoardLoading} = useGetAllBoards()
  const {
    columnResponse: columns,
    fetchColumnHandler,
    isColumnLoading,
  } = useGetColumns(boardId || params.boardId)

  const initialValues = {
    cardName: cardDetails?.card_name && cardDetails?.card_name.trim(),
    dueDate: cardDetails?.due_date,
    dueTime: cardDetails?.due_time,
    boardName: cardDetails?.board_name,
    columnName: cardDetails?.board_column_id,
    status: cardDetails?.status,
    priority: cardDetails?.priority,
  }
  const { pagesByBoardId:pages } = useSelector((state: any) => state.pageReducer)

  // let board = boards?.filter((item: any) => params.boardId === item.id)
  // let isPageArchiveBoard = window.location.href.includes('archived')
  const {sendJsonMessage}: any = useWebSocketHook()
  const {fileErrorMsg, selectedFile, setSelectedFile, selectFileHandler, setFileErrorMsg} =
    useFileUploader()

  const {
    optionsOfAssignee,
    isUserAssignLoading,
    selectAutoAssignee,
    setSelectAutoAssignee,
    optionsForSendAlert,
    boardPersonsForSendAlert,
    getBoardPersonHandler,
  } = useBoardPersons(cardDetails)
  const isRenderStore = useSelector((state: any) => state.isRenderReducer)

  const {isRender} = isRenderStore.isRender

  const {
    sendAlertTo,
    setSendAlertTo,
    sendAlertToComment,
    setSendAlertToComment,
    sendAlertMsg,
    sendAlertMsgComment,
    sendAlertToBoardPerson,
    setSendAlertMsg,
  } = useSendAlert(
    boardPersonsForSendAlert,
    cardDetails,
    boardId,
    editorComment,
    cardDescription
  )

  const deleteFileHandler = async (name: string, key: string, value: string) => {
    let files: any
    if (key) {
      files = selectedFile.filter((item: any) => item.key !== key)
    } else {
      files = selectedFile.filter((item: any) => item.name !== name)
    }
    try {
      const fileNameForDelete = value || name
      await s3.deleteFile(fileNameForDelete)
      try {
        setSelectedFile(files)
        await deleteFileAttachment(cardDetails?.id, key, id, boardId)
      } catch (error) {
        return error
      }
    } catch (error) {
      return error
    }
  }

  const handleSubmission = async () => {
    const urls = []
    for (let index = 0; index < selectedFile.length; index++) {
      const filename = selectedFile[index].name
      if (filename) {
        const filenameUpdated = filename.substring(0, filename.lastIndexOf('.'))
        try {
          const uploadFile: any = await s3.uploadFile(selectedFile[index], filenameUpdated)
          urls.push(uploadFile.location)
        } catch (error) {
          return error
        }
      }
    }

    return urls
  }
  const deleteCardHandler = async () =>{
    setDisableDeleteCardBtn(true)
    try{
      const res = await deleteCardById(cardDetails.id)
      setDeleteCardMsg(res.data.message)
      dispatch(fetchAllCardsofLoggedInUser())
      setTimeout(() => {
        if (!isLastUpdate) {
          const filteredCards = isHomePage
            ? cards?.filter((card: any) => card.id !== cardDetails.id)
            : allCards?.filter((card: any) => card.id !== cardDetails.id);
        
            setCards(filteredCards);
          }
        if(isLastUpdate) dispatch(setIsRender({isRender: !isRender}))
        dispatch(setIsCardUpdated({isCardUpdated: !isCardUpdated}))
        setCardModalShow(false)
       }, 3000);
    }
    catch{
      // console.log(error)
    }
  }

  // const EditorImageViewerHandler = () => {
  //     var editImg: any = document.querySelectorAll(`div.ql-editor[contenteditable="true"] img`)
  //     for (let i = 0; i < editImg.length; i++) {
  //         editImg[i].style.maxWidth = '30%';
  //         editImg[i].classList.add('m-2');
  //         editImg[i].classList.add('cursor-pointer');
  //         editImg[i].addEventListener('click', () => {
  //             let imageSrc: any = editImg[i].getAttribute('src')
  //             setImageSrc(imageSrc)
  //             setShow(true)
  //         })
  //     }
  // }

  const gotoBoardHandler = () => {
    formik.handleSubmit()
    navigate(`/board-view/board/${cardDetails?.boardId}`)
  }
   const getAddedAndRemovedTags = () => {
      const addedTags = selectedTags?.filter((tag: any) => !transformedTags?.some((t: any) => t.value === tag.value));
      const removedTags = transformedTags?.filter((tag: any) => !selectedTags?.some((t: any) => t.value === tag.value));
      setTagsToUpdate({ addedTags, removedTags });
    };
    // const handleUpdateEditorComment = (boardName:string,key:string) => {
    //   setEditorComment((prev: any) => {
    //     const newComment = `This board was moved to board: <em>${boardName} as ${key}</em>`;
    //     return prev !== newComment ? newComment : prev; // Only update if the new value is different
    //   });
      
    // };
    
    const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: cardSchema,
    onSubmit: async (values) => {

      setDisableBtn(true)
      const {utcDate, utcTime}: any = getTimeDateFormate(startDate)
      const fileLinks = await handleSubmission()  

      const postDataBeforeRule = {
        created_by: fullName,
        last_modified_by: fullName,
        board_name: allBoards.find((item: any) => item.board.id === boardId)?.board.name.trim(),
        card_name: values.cardName,
        description: cardDetails?.id === cardIdForDesc && cardDescription,
        due_date: startDate ? utcDate : null,
        due_time: startDate ? utcTime : null,
        number: `${cardLength || cardDetails?.number}`,
        position: cardDetails?.position,
        priority: values?.priority === 'Select' ? null : values?.priority || cardDetails?.priority,
        rag: null,
        status: values?.status,
        version: 1,
        applicationId: null,
        boardId: boardId,
        board_column_id: columnId,
        categoryId: null,
        componentId: null,
        customerId: null,
        productId: null,
        typeId: null,
        reporter: fullName,
        new_tagIds : tagsToUpdate?.addedTags?.length > 0 
          ? tagsToUpdate.addedTags.map((tag: any) => tag.value) 
          : [],      
        remove_tagIds : tagsToUpdate?.removedTags?.length > 0 
          ? tagsToUpdate.removedTags.map((tag: any) => tag.value) 
          : [],
      assignees:
          optionsOfAssignee.length > 0
            ? selectAutoAssignee.map((item: any) => {
                return {
                  board_person_id: item.value,
                }
              })
            : [],
        file_attachments: fileLinks,
        updateReminders: convertIdIntoCardReminderId(reminders as any),
        removeReminders: deleteReminderIds,
      }
      let postData:any
      const currentCol=columns?.find((col:any)=> col.id === columnId)

      //Check to see if rules is needed to apply
      if(cardDetails.board_column_id === currentCol?.id){
        postData=postDataBeforeRule
      }else{
        postData = applyColumnRules(postDataBeforeRule, currentCol);
      }

      let updatedCard:any
      try {
      // Check to see if card is updating or auto cloning to another board
        if(cardDetails?.boardId !== boardId){

          // Change status to Archive of original card
          const archiveCardData:any = {
            ...postData,
            status: "archived",
            priority: cardDetails.priority,
            boardId: cardDetails.boardId,
            board_column_id: cardDetails.board_column_id,
            board_name: allBoards.find((item: any) => item.board.id === boardId)?.board.name.trim(),
          };
          const response=await updateCardById(archiveCardData, cardDetails?.id, id, boardId)
          updatedCard=response.data.data.card
          setEditorComment(`This card has been moved to the board: <em>${postData.board_name}</em>`)
          await autoCloneCard(postData,id,cardDetails)
          // handleUpdateEditorComment(postData.board_name,res.data.data.key)
      }
        else{
          // Simply updating a card
          const response=await updateCardById(postData, cardDetails?.id, id, boardId)
          updatedCard=response.data.data.card
        }
        if(!isLastUpdate){
          const filteredCards =isHomePage? cards?.filter((card : any)=> card.id !== cardDetails.id ) : allCards?.filter((card : any)=> card.id !== cardDetails.id )
          const checkAssignee = updatedCard.assignees.find((item:string)=>item===fullName)
          if(isHomePage){
  
            if(checkAssignee) {
              setCards([...filteredCards, updatedCard])
            }else{
              setCards(filteredCards)
              
            }
          }else{
            setCards((prevCards:any) =>
              prevCards.map((card:any) =>
                card.id === updatedCard.id ? { ...card, ...updatedCard } : card
              )
            );
          }
        }
        const addedTags = tagsToUpdate.addedTags.map((tag: any) => ({ label: tag.label, value: tag.value }));
        addedTags.length && dispatch(addBoardTags(addedTags));
    
        const removedTagIds = tagsToUpdate.removedTags.map((tag: any) => tag.value);
        removedTagIds.length && dispatch(removeBoardTags(removedTagIds));
        const webSocketPayload: any = {
          event: 'publish',
          channel: boardId,
          userId: id,
          command: 'UPDATE',
          operation: webSocketOperations.cardUpdate,
          payload: {
            boardId: postData.boardId,
            cardId: cardDetails?.id,
            name: postData.card_name,
            priority: postData.priority,
            boardColumnId: postData.board_column_id,
            alert: `${capitalizeEachWord(fullName)} did changes in ${postData.board_name}- ${
              postData.number
            }.`,
          },
        }
        if (boardId !== cardDetails?.boardId) {
          sendJsonMessage(webSocketPayload)
          webSocketPayload.channel = cardDetails?.boardId
          sendJsonMessage(webSocketPayload)
        } else {
          sendJsonMessage(webSocketPayload)
        }
        
        childCommentRef.current.addComment()
        childCommentRef.current.updateComment()
        if(isLastUpdate) dispatch(setIsRender({isRender: !isRender}))

        dispatch(setIsCardUpdated({isCardUpdated: !isCardUpdated}))
      } catch (error) {
        return error
      } finally {
        setDisableBtn(false)
        setSelectedFile([])

        // if (location.pathname !== '/home/board' && location.pathname !== '/home') {
          // dispatch(fetchAllCardsofLoggedInUser())
          // dispatch(fetchAllCardByBoardId({boardId: boardId, userId: id}))
        // }
        setCardModalShow(false)
      }
      setBoardId('')
    },
  })

  function convertIdIntoCardReminderId(reminders: any) {
    const updatedReminders: any = reminders.map(({id, ...reminder}: any) => {
      return {...reminder, cardReminderId: id}
    })

    const removeNulls = removeNullFromReminder(updatedReminders)
    return removeNulls
  }


  useEffect(() => {
    const urlLinks: any = []
    if (cardDetails?.file_attachments) {
      for (const file of cardDetails?.file_attachments) {
        for (const [key, value] of Object.entries(file)) {
          const getFileName = (value as string)
            .toLowerCase()
            ?.split(`${process.env.REACT_APP_ENVIRONMENT}/`)[1]
          urlLinks.push({key, value: value, showName: getFileName})
        }
      }
      setSelectedFile(urlLinks)
    }
    return () => setSelectedFile([])
    //eslint-disable-next-line
  }, [])

  useEffect(() => {

    if (boardId) {
      getBoardPersonHandler(boardId)
      fetchColumnHandler(boardId)
    }
    //eslint-disable-next-line
  }, [boardId,pages])

  useEffect(() => {
    if (cardDetails?.card_reminders?.length > 0) {
      const filteredReminders = cardDetails?.card_reminders?.map((reminder: any) => {
        //eslint-disable-next-line
        const {cardId, createdAt, updatedAt, ...rest} = reminder
        return Object.entries(rest).reduce((acc: any, [key, value]: [string, any]) => {
          if (value !== null) {
            acc[key] = value
          }
          return acc
        }, {})
      })

      if (filteredReminders.length > 0) {
        setReminders([...filteredReminders])
      }
    }

    setCardDescription(cardDescription)
    //eslint-disable-next-line
  }, [])
  useEffect(() => {
    getAddedAndRemovedTags()
  }, [selectedTags,cardDetails?.id])

  const handleCloneDropdown = () => {
    handleCloneModalShow()
    setToClone(cardDetails)
  }
  const {theme} = useTheme()

  return (
    <>
      {cloneModalShow && (
        <CloneCardModal
          isHomePage={isHomePage}
          loading={loading}
          closeModal={handleCloneModalClose}
          showModal={cloneModalShow}
          onCloneHandler={cloneCardHandler}
          card={cardDetails}
          toClone={toClone}
          cardDescription={cardDescription}
        />
      )}
      <Modal
      contentClassName={theme === 'dark' ? 'theme__div modal__bg' : ''}
        show={cardModalShow}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        animation={false}
        onHide={() => {
          setCardModalShow(false)
          setSelectedFile([])
        }}
        scrollable
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {cardDetails?.board_name}- {cardDetails?.number}
                    </Modal.Title>
                </Modal.Header> */}
        <div className='row d-flex align-items-center justify-content-between border-bottom fs-2 fw-bold p-8'>
          <div className='text-primary col-6'>

            <span className={`responsive-font ${theme === 'dark' ? 'white__text' : ''} `} style={{marginRight: 20}}>
              
              {longWordSlicing(cardDetails?.key?.trim())}
            </span>

            {isBoard === false && (
              <>
                <Button
                  className='btn-custom btn-custom-secondary goto-board-btn  btn btn-primary py-2 px-3'
                  onClick={gotoBoardHandler}
                >
                  Go To Tasks
                </Button>

                <span onClick={gotoBoardHandler} className='link-span responsive-font'>
                  Go To Tasks
                </span>
              </>
            )}
          </div>
          <div className='col-4 text-end'>
            <Button
              disabled={(boardId?.length > 0 && columnId) || disableBtn ? false : true}
              className={`${disableBtn && 'btn-custom-secondary '} btn-sm w-75`}
              onClick={() => {
                formik.handleSubmit()
              }}
            >
              {disableBtn ? <Spinner animation='border' variant='light' /> : 'Save'}
            </Button>
          </div>

          <div
            className='options-button col-1 text-end m-auto'
            onClick={(e) => e.stopPropagation()}
          >
            <DropdownClone handleShowModal={handleCloneDropdown} cardData={cardDetails} category={'card'} />
          </div>

          <div className={`col-1 d-flex ${cardDetails?.status === 'archived' ? 'justify-content-between' :'justify-content-end' } align-items-center`}>
            {cardDetails?.status === 'archived' ? 
                (<div className='cursor-pointer bg-danger text-center py-1 px-2 me-2 d-flex justify-content-center align-items-center' style={{borderRadius:'8px'}}>
                  <span onClick={handleShow}><i className={` bi bi-trash fs-3 p-1 mx-1 text-white`}></i></span>
                </div>)
                : null}
          <div className=' text-end'>
            <span
              className='text-center p-2 cursor-pointer d-inline-block pb-0'
              onClick={() => setCardModalShow(false)}
            >
              X
            </span>
          </div>
          </div>
        </div>
        <Modal.Body>
          <div className='container'>
            {/* {isColumnLoading || isBoardLoading ? (
              <EditCardSkeleton />
            ) : ( */}
              <UpdateCardForm
                formik={formik}
                setCardLength={setCardLength}
                boardId={boardId}
                allBoardsVar={allBoards}
                setBoardId={setBoardId}
                cardDetails={cardDetails}
                columns={columns}
                columnId={columnId}
                setColumnId={setColumnId}
                // handleChange={EditorImageViewerHandler}
                deleteFileHandler={deleteFileHandler}
                selectedFile={selectedFile}
                changeHandler={selectFileHandler}
                fileErrorMsg={fileErrorMsg}
                setFileErrorMsg={setFileErrorMsg}
                sendAlertMsg={sendAlertMsg}
                setSendAlertMsg={setSendAlertMsg}
                setSendAlertTo={setSendAlertTo}
                sendAlertTo={sendAlertTo}
                childCommentRef={childCommentRef}
                optionsForSendAlert={optionsForSendAlert}
                setSendAlertToComment={setSendAlertToComment}
                sendAlertToComment={sendAlertToComment}
                sendAlertToBoardPerson={sendAlertToBoardPerson}
                boardPersonsForSendAlert={boardPersonsForSendAlert}
                setEditorComment={setEditorComment}
                editorComment={editorComment}
                sendAlertMsgComment={sendAlertMsgComment}
                setStartDate={setStartDate}
                startDate={startDate}
                options={optionsOfAssignee}
                setSelected={setSelectAutoAssignee}
                selected={selectAutoAssignee}
                disableBtn={disableBtn}
                setEditor={setCardDescription}
                editor={cardDescription}
                checkIsDescriptionExist={checkIsDescriptionExist}
                setReminders={setReminders}
                reminders={reminders}
                setDeletedReminderIds={setDeletedReminderIds}
                isColumnLoading={isColumnLoading}
                isBoardLoading={isBoardLoading}
                isUserAssignLoading={isUserAssignLoading}
                isCardDescriptionLoading={isCardDescriptionLoading}
                setCards={setCards}
                tags={tags}
                loading={tagsLoading}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                transformedTags={transformedTags}
              />
            {/* )} */}
          </div>
        </Modal.Body>
      </Modal>
      <DeleteCardModal  deleteCardMsg={deleteCardMsg} setDeleteCardMsg={setDeleteCardMsg} disableBtn={disableDeleteCardBtn} showCardDelete={showCardDelete} handleClose={handleClose} deleteCardHandler={deleteCardHandler} cardName={longWordSlicing(cardDetails?.key?.trim())} />
      {/* {imageSrc && <ImageModal imageSrc={imageSrc} setShow={setShow} show={show} />
            }
            {video.length > 0 && (
                <VideoShowModal videoSrc={video} setShowVideo={setShowVideo} showVideo={showVideo} />
            )} */}
    </>
  )
}

export default EditNewCard
