import { useState, useEffect } from 'react'
import { CopyObjectCommand } from "@aws-sdk/client-s3"
import styled from '@emotion/styled'
import { colors } from '@atlaskit/theme'
import { borderRadius, grid } from '../constants'
import EditNewCard from '../../UpdateCard/EditBoardCard'
import { useParams } from 'react-router-dom'
import { capitalizeEachWord } from '../../../helper-functions/CapitalizeName'
import { getFileExtension } from '../../../helper-functions/GetImageExtension'
import { convertDateTimeToLocal, isLocalTimeBeforeUtc } from '../../../helper-functions/TimeFormate'
import { longWordSlicing } from '../../../helper-functions/CheckString'
import CloneCardModal from '../../Modals/CloneCardModal'
import { cloneCard } from '../../../services/cards'
import { useAuth } from '../../../modules/auth'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { s3Client } from "../../../config/awsFileUpload"
import { setIsCardUpdated } from '../../../store/slice/last-update-slice'
import DropdownClone from "../../CloneCardDropdown"
import { v4 as uuidv4 } from 'uuid'
import '../Column.css'
import { useTheme } from '../../../context/ThemeContext'
import { defaultColor, priorityColors } from '../../../utils/priorities'
import { addAllCurrentUserCards } from '../../../store/slice/card-slice'
const getBackgroundColor = (isDragging: any, isGroupedOver: any, authorColors: any) => {
  if (isDragging) {
    return authorColors.soft
  }

  if (isGroupedOver) {
    return colors.N30
  }

  return colors.N0
}

const getBorderColor = (isDragging: any, authorColors: any) =>
  isDragging ? authorColors.hard : 'transparent'
const getOptionColor = (value: string) => {
  if(value){
    return priorityColors[value]
  }
};
const Container = styled.a`
  border-radius: ${borderRadius}px;
  border: ${(props: any) => `2px solid ${getOptionColor(props?.priority)}`};
  // border-color: ${(props: any) => getBorderColor(props.isDragging, props.colors)};
  background-color: ${(props: any) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }: any) => (isDragging ? `2px 2px 1px ${colors.N70}` : 'none')};
  padding: ${grid}px;
  min-height: 40px;
  margin-bottom: ${grid}px;
  user-select: none;
  cursor: pointer !important;
  /* anchor overrides */
  color: ${colors.N900};

  &:hover,
  &:active {
    color: ${colors.N900};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: ${(props: any) => props.colors.hard};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`

// const Avatar = styled.img`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   margin-right: ${grid}px;
//   flex-shrink: 0;
//   flex-grow: 0;
// `

const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`

// const BlockQuote = styled.div`
//   &::before {
//     content: open-quote;
//   }
//   &::after {
//     content: close-quote;
//   }
// `

const Footer = styled.div`
  display: flex;
  // margin-top: ${grid}px;
  align-items: center;
`

// const Author = styled.small`
//   flex-grow: 0;
//   margin: 0;
//   background-color: ${(props: any) => props.colors.soft};
//   border-radius: ${borderRadius}px;
//   font-weight: normal;
//   padding: ${grid / 2}px;
// `
const Assignee = styled.small`
  flex-grow: 0;
  margin: 0;
  border-radius: ${borderRadius}px;
  font-weight: normal;
  padding: ${grid / 2}px;
  color: ${(props: any) => {
    if(props.theme === 'light'){
    switch (props.priority) {
      case 'Critical':
        return priorityColors.Critical;
      case 'High':
        return priorityColors.High;
      case 'Medium':
        return priorityColors.Medium;
      case 'Low':
        return priorityColors.Low;
      case 'Future':
        return priorityColors.Future;
      default:
        return defaultColor;
    }}
  }};
`

const QuoteId = styled.small`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  // text-align: right;
`

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CardItem = ({ cards,setCards,homeCards, fetchSortedCardHandler, quote, isDragging, isGroupedOver, provided, handleCloneCard, highestNumber ,allCards}: any) => {



  const [toClone, setToClone] = useState({})
  const [loading, setLoading] = useState(false)
  const [cardModalShow, setCardModalShow] = useState(false)
  const [cloneModalShow, setCloneModalShow] = useState(false);
  const handleCloneModalClose = () => setCloneModalShow(false);
  const handleCloneModalShow = () => setCloneModalShow(true);
  const { currentUser } = useAuth()
  const { id } = currentUser?.data.user
  const params = useParams()
  const dispatch: Dispatch<any> = useDispatch()
  const isLastUpdated = useSelector((state: any) => state.lastUpdateReducer)
  const { isCardUpdated } = isLastUpdated.isCardUpdated
  const {theme} = useTheme()
  // const { localDate }: any = convertDateTimeToLocal(quote.due_date, quote.due_time)
  useEffect(() => {

    if (Number(quote.number) === Number(params.cardNumber)) {
      setCardModalShow(true)
    }
    //eslint-disable-next-line
  }, [])

  const cloneCardHandler = async (card: any, selectedKeys: string[],cardDescription:string,finalTitle:string) => {
    try {
      setLoading(true)
      let payload: any = {
        cardid: card.id,
        created_by: card.created_by,
        last_modified_by: card.last_modified_by,
        board_name: card.board_name.trim(),
        number: (highestNumber + 1).toString(),
        position: Number(card?.position)+1,
        boardId: card.boardId,
        board_column_id: card.board_column_id,
        reporter: card.reporter,
        cardType: card.cardType,
        description: cardDescription && cardDescription, 
        card_name: finalTitle
      };



      for (const key of selectedKeys) {
        if (key.toString() === "card_name") {
          continue;
        }
        if (Object.prototype.hasOwnProperty.call(card, key)) {
          if (key.toString() === "file_attachments") {

            if (card.file_attachments && card.file_attachments.length > 0) {
              for (const file of card.file_attachments) {
                const filename = uuidv4();
                const fileUrl = (Object.values(file) as string[])[0];


                const getFileName = fileUrl.split(`${process.env.REACT_APP_ENVIRONMENT}/`)[1]
                const copySource = `${process.env.REACT_APP_FILE_UPLOAD_BUCKET}/cards-attachments/${process.env.REACT_APP_ENVIRONMENT}/${getFileName}`;

                const destinationKey = `cards-attachments/${process.env.REACT_APP_ENVIRONMENT}/${filename}.${getFileExtension(fileUrl)}`;

                const command = new CopyObjectCommand({
                  CopySource: copySource,
                  Bucket: process.env.REACT_APP_FILE_UPLOAD_BUCKET,
                  Key: destinationKey,
                });

                if (payload["file_attachments"] === undefined) {
                  payload["file_attachments"] = [
                    `https://${process.env.REACT_APP_FILE_UPLOAD_BUCKET}.s3.amazonaws.com/${destinationKey}`
                  ];
                } else {
                  payload["file_attachments"] = payload.file_attachments.map((item: any) => item);
                  payload["file_attachments"].push(`https://${process.env.REACT_APP_FILE_UPLOAD_BUCKET}.s3.amazonaws.com/${destinationKey}`);
                }

                try {
                  await s3Client.send(command);
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error('Error copying object:', error);
                }
              }
            }
          } else if (key.toString() === "assignees") {

            if (card.assigneeBoardPersonIds && card.assigneeBoardPersonIds.length > 0) {
              const allAssignees = card.assigneeBoardPersonIds.map((assignee: any) => {
                return {
                  board_person_id: assignee.boardPersonId
                };
              });
              payload[key] = allAssignees;
            }
          } else if (key.toString() === "due_date") {
            payload[key] = card[key];
            payload["due_time"] = card["due_time"];
          } else if(key.toString() === "tags"){
            payload["tagIds"] = card.tags.length > 0 ? card.tags.map((tag: any) => { return tag.id} ) : []
          }
          else {
            payload[key] = card[key];
          }
        }
      }

      if (!Object.prototype.hasOwnProperty.call(payload, 'status')) {
        payload = { ...payload, status: 'todo' };
      }


      const res: any = await cloneCard(payload, id);
      handleCloneCard(res.data.data);
      dispatch(setIsCardUpdated({ isCardUpdated: !isCardUpdated }));
      dispatch(addAllCurrentUserCards(res.data.data));

    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false)
      handleCloneModalClose();
      setCardModalShow(false)
    }
  };


 
  const handleCloneDropdown = () => {
    handleCloneModalShow()
    setToClone(quote)
  }


return (
  <>
    {cloneModalShow && (
      <CloneCardModal loading={loading} closeModal={handleCloneModalClose} showModal={cloneModalShow} onCloneHandler={cloneCardHandler} card={toClone} />
    )
    }
    <Container
       className={`${theme === 'dark' ? 'card__theme__dark' : ''}`}
      onClick={() => {
        setCardModalShow(true)
      }
      }
      priority={quote.priority}
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      colors={quote.author.colors}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {/* <Avatar src={quote.author.avatarUrl} alt={quote.author.name} /> */}
      <Content>
        <div className='d-flex justify-content-between '>
          <p className='fs-8'>
          {longWordSlicing(quote.key.trim())}

          </p>

          <div className='options-button' onClick={(e) => e.stopPropagation()}>
            <DropdownClone handleShowModal={handleCloneDropdown} cardData={quote} category={'card'} />
           
          </div>
        </div>
        <p className='fs-7 fw-bold mb-0 word-break-custom-class'>{quote.card_name}</p>
        {/* <p className='fs-7 fw-bold mb-0'>{longWordSlicing(quote.card_name)}</p> */}

        <Footer className='mt-7'>
          <QuoteId>Status</QuoteId>
          <small
            className={`${quote.status === 'todo'
              ? 'bg-warning'
              : quote.status === 'done'
                ? 'bg-success'
                : quote.status === 'in-progress' ? 'bg-primary' 
                : 'bg-archive'
              } ${theme === 'dark' ? 'priority__color' : 'text-white' } p-2`}
          >
            <span>
              {quote.status === 'todo'
                ? 'To Do'
                : quote.status === 'done'
                  ? 'Done'
                  : quote.status === 'in-progress' 
                  ? 'In Progress' 
                  : 'Archived'}
            </span>
          </small>
        </Footer>

        {quote.priority &&
          <Footer className=''>
            <QuoteId>Priority</QuoteId>
            <Assignee theme={theme}  priority={quote?.priority}>{quote?.priority}</Assignee>
          </Footer>
        }

        {quote.assignees && (
          <Footer className=''>
            <QuoteId>Assigned to</QuoteId>
            <Assignee className={`text-truncate text-end  ${theme === 'dark' ? 'white__text' : ''}`} style={{ width: '145px' }}>
              {quote.assignees?.map((item: any) => capitalizeEachWord(item) + ',')}
            </Assignee>
          </Footer>
        )}
        {
          quote.due_date &&
          <Footer className=''>
            <QuoteId>Due Date:</QuoteId>
            <Assignee className={theme === 'dark' ? 'white__text' : ''} style={{
              background: `${quote && isLocalTimeBeforeUtc(quote.due_date, quote.due_time) === true ? "#FC99A3" : ""}`
            }}>{convertDateTimeToLocal(quote.due_date, quote.due_time)}</Assignee>
            {/* <Assignee >{localDate} at {convertTime24to12(localTime)}</Assignee> */}
          </Footer>
        }

      </Content>
    </Container >
    {cardModalShow && (
      <EditNewCard
        cardDetails={quote}
        cardTitle='Edit Card'
        cardModalShow={cardModalShow}
        setCardModalShow={setCardModalShow}
        fetchSortedCardHandler={fetchSortedCardHandler}
        // fetchCardHandler={fetchCardHandler}
        homeCards={homeCards}
        setToClone={setToClone}
        cloneCardHandler={cloneCardHandler}
        loading={loading}
        handleCloneModalClose={handleCloneModalClose}
        setCloneModalShow={setCloneModalShow}
        cloneModalShow={cloneModalShow}
        handleCloneModalShow={handleCloneModalShow}
        setCards={setCards}
        cards={cards}
        allCards={allCards}
      />
    )
    }
  </>
)
}

export default CardItem
