import { Button, Modal } from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'
import { FC } from 'react'
import CustomAlert from '../CustomAlert'

const DeleteCardModal: FC<any> = ({ deleteCardMsg, setDeleteCardMsg, disableBtn, showCardDelete, handleClose, deleteCardHandler, cardName }: any) => {
    const { theme } = useTheme()
    return (
        <Modal contentClassName={theme === 'dark' ? 'theme__div' : ''} show={showCardDelete} onHide={handleClose} >
            <Modal.Header >
                <Modal.Title className={theme === 'dark' ? 'white__text' : ''}>Delete &ldquo;{cardName}&ldquo;</Modal.Title>
                <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={handleClose}></i>
            </Modal.Header>
            <Modal.Body className={` ${theme === 'dark' ? 'white__text' : ''}`}>
                You are about to permanently delete  &ldquo;{cardName}&ldquo;. Do you wish to proceed?
            </Modal.Body>
            <Modal.Footer>
                {deleteCardMsg.length > 0 ?
                    <CustomAlert
                        className='w-100'
                        variant={'danger'}
                        alertMessage={deleteCardMsg}
                        setAlertMessage={setDeleteCardMsg}
                        time={4000}
                    />
                    :
                    (<>
                        <Button variant='secondary' onClick={handleClose}>
                            No
                        </Button>

                        <Button variant='primary' onClick={deleteCardHandler} disabled={disableBtn}>
                            Yes
                        </Button></>)}
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteCardModal