import React from 'react'
import {FileUploadPage} from '../FileUploader/FileUpload'
import {TextEditor} from '../TextEditor'
import clsx from 'clsx'
import SelectBoard from '../Select/SelectBoard'
import SelectColumn from '../Select/SelectColumn'
import AssignTo from '../Select/AssignTo'
import CustomDatePicker from '../CustomDatePicker'
import SendAlertTo from '../Select/SendAlert'
import CustomAlert from '../CustomAlert'
import Remainder from '../Remainder'
import '../../styles/updateanaddform.css'
import {removeDuplication} from '../../helper-functions/CapitalizeName'
import {useTheme} from '../../context/ThemeContext'
import { defaultColor, defaultWhiteColor, priorityColors } from '../../utils/priorities'
import SelectTags from '../Select/SelectTags'
import Skeleton from 'react-loading-skeleton'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AddCardForm = ({
  formik,
  allBoardsVar,
  setBoardId,
  boardId,
  columnTitle,
  columnIdProp,
  columns,
  setColumnId,
  columnId,
  fileErrorMsg,
  selectedFile,
  deleteFileHandler,
  changeHandler,
  setStartDate,
  startDate,
  options,
  setSelected,
  selected,
  setFileErrorMsg,
  // disableBtn,
  // board,
  // setIsSendAlert,
  editor,
  setEditor,
  optionsForSendAlert,
  sendAlertMsg,
  setSendAlertTo,
  sendAlertTo,
  // sendAlertToBoardPerson,
  setSendAlertMsg,
  reminders,
  setReminders,tags, setSelectedTags, selectedTags,tagsLoading,role
}: any): React.JSX.Element => {
  const getOptionColor = (value: string,theme: string) => {
    if(theme === 'dark'){
      return defaultWhiteColor
    }else{
      switch (value) {
        case 'Critical':
          return priorityColors.Critical;
        case 'High':
          return priorityColors.High;
        case 'Medium':
          return priorityColors.Medium;
        case 'Low':
          return priorityColors.Low;
        case 'Future':
          return priorityColors.Future;
        default:
          return defaultColor;
      }
    }
  }

  const {theme} = useTheme()
  return (
    <form className='form row' onSubmit={formik.handleSubmit} noValidate>
      <div className='col-md-12 col-lg-8 col-sm-12'>
        <div className='row mb-5'>
          <div className='col-md-12 col-lg-12 col-sm-12'>
            <label
              className={`form-label fs-6 fw-bolder ${
                theme === 'dark' ? 'white__text' : 'text-dark'
              } `}
            >
              Title
            </label>
            <input
              autoFocus
              {...formik.getFieldProps('cardName')}
              className={clsx(
                `form-control form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`,
                {'is-invalid': formik.touched.cardName && formik.errors.cardName},
                {
                  'is-valid': formik.touched.cardName && !formik.errors.cardName,
                }
              )}
              type='name'
              name='cardName'
              autoComplete='off'
              maxLength={300}
              placeholder='Enter Task Name'
              tabIndex={1}
            />
            {formik.touched.cardName && formik.errors.cardName && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger'>
                  {formik.errors.cardName}
                </span>
              </div>
            )}
          </div>
        </div>
        <h6 className={`my-3  ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>Description</h6>
        <TextEditor tabIndex={2} handleFormSubmission={formik.handleSubmit} boardId={boardId} placeholder={'Write something'} setEditor={setEditor} editor={editor} />

        <h6 className={`mt-6  ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>
          Send Alert To:
        </h6>
        <div className='row my-2'>
          <div className='col-md-10'>
            <SendAlertTo
              options={optionsForSendAlert}
              setSelected={setSendAlertTo}
              selected={sendAlertTo}
            />
          </div>
          <div className='col-md-2  alert-btn'>
            <button
              className='btn btn-info px-2 pt-3 pb-2'
              type='button'
              onClick={() => {
                if (sendAlertTo.length > 0 && editor !== null) {
                  formik.handleSubmit()
                } else {
                  setSendAlertMsg('Please enter description for send alert.')
                }
              }}
            >
              Send Alert
            </button>
          </div>
          <div className='col-md-10'>
            <CustomAlert
              variant={'warning'}
              className={'mt-2'}
              alertMessage={sendAlertMsg}
              setAlertMessage={setSendAlertMsg}
            />
          </div>
        </div>

        <FileUploadPage
          selectedFile={selectedFile}
          deleteFileHandler={deleteFileHandler}
          changeHandler={changeHandler}
        />

        <CustomAlert
          variant={'warning'}
          alertMessage={fileErrorMsg}
          setAlertMessage={setFileErrorMsg}
        />
      </div>
      <div className='col-md-12 col-lg-4 col-sm-12 border px-4 py-3'>
        <h4 className={` text-center ${theme === 'dark' ? 'white__text' : ''}`}>Details</h4>

        <div className='row mb-5'>
          <div className='col-md-12 my-2'>
            <h6 className={theme === 'dark' ? 'white__text' : ''}>Assigned to:</h6>
            <AssignTo
              page={false}
              options={options}
              setSelected={setSelected}
              selected={removeDuplication(selected)}
            />
          </div>

          <div className='col-12 my-2'>
            <label className={`${theme === 'dark' ? 'white__text' : 'text-dark'} form-label fs-6 fw-bolder `}>Board</label>
            <SelectBoard
              // board={board}
              boards={allBoardsVar}
              setBoardId={setBoardId}
              boardId={boardId}
            />
          </div>
          <div className='col-12 my-2'>
            <label className={`${theme === 'dark' ? 'white__text' : 'text-dark'} form-label fs-6 fw-bolder `}>Column</label>
            <SelectColumn
              columnTitle={columnTitle}
              columnIdProp={columnIdProp}
              columns={columns}
              setColumnId={setColumnId}
              columnId={columnId}
            />
          </div>
        </div>

        <div className='fv-row mb-3 col-12 my-2'>
          <label className={`${theme === 'dark' ? 'white__text' : 'text-dark'} form-label fs-6 fw-bolder `}>Due Date & Time</label>
          <div className={`w-100  ${theme === 'dark' ? 'react_date_dark' : ''}`}>
            <CustomDatePicker setStartDate={setStartDate} startDate={startDate} />
          </div>
        </div>
        <div className='fv-row mb-3 my-2 d-flex justify-content-between align-items-center'>
          <label className={`${theme === 'dark' ? 'white__text' : 'text-dark'} form-label fs-6 fw-bolder `}>Reminder</label>
          <div className='d-flex justify-content-end' style={{width: '100%'}}>
            <Remainder reminders={reminders} setReminders={setReminders} isDisable={startDate} />
          </div>
        </div>

        <div className='mb-3 row mt-2'>
          <label className={`col-sm-4 col-form-label ${theme === 'dark' ? 'white__text' : ''}`}>Status</label>
          <div className='col-sm-8'>
            <select
              {...formik.getFieldProps('status')}
              className={clsx(
                `form-control form-control-lg form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`,
                {'is-invalid': formik.touched.status && formik.errors.status},
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
            >
              <option value='todo'>To Do</option>
              <option value='in-progress'>In Progress</option>
              <option value='done'>Done</option>
              <option value='archived'>Archived</option>
            </select>
            {formik.touched.status && formik.errors.status && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger'>
                  {formik.errors.status}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className='mb-3 row'>
          <label className={`col-sm-4 col-form-label ${theme === 'dark' ? 'white__text' : ''}`}>Priority</label>
          <div className='col-sm-8'>
            <select
              {...formik.getFieldProps('priority')}
              className={clsx(
                `form-control form-control-lg form-control-solid ${theme === 'dark' ? 'dark__theme__input__no__color' : ''} `,
                {'is-invalid': formik.touched.priority && formik.errors.priority},
                {
                  'is-valid': formik.touched.priority && !formik.errors.priority,
                }
              )}
              style={{color: getOptionColor(formik.values.priority,theme)}}
            >
              <option className={theme === 'dark' ? 'label-color' : ''} value={undefined}>Select</option>
              {['Critical', 'High', 'Medium', 'Low', 'Future'].map((option, index) => (
                <option key={index} value={option} style={{color: getOptionColor(option,theme)}}>
                  {option}
                </option>
              ))}
            </select>
            {formik.touched.priority && formik.errors.priority && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger'>
                  {formik.errors.priority}
                </span>
              </div>
            )}
          </div>
        </div>


        <div className='mb-3'>
          <label className={`col-form-label ${theme === 'dark' ? 'white__text' : ''}`}>Tags</label>
          <div className=''>
          {tagsLoading ? (
            <Skeleton count={1} height={40} />
          ) :
          (<SelectTags transformedTags={undefined} tags={tags} setSelectedTags={setSelectedTags} selectedTags={selectedTags} role={role} />)}
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddCardForm
