import { cloneCard } from "../services/cards";

export const autoCloneCard: any = async (postData: any, userId: string,cardDetails:any) => {
    try {
        const payload: any = {
            created_by: postData.created_by,
            last_modified_by: postData.last_modified_by,
            board_name: postData.board_name.trim(),
            number: postData.number,
            position: postData.position,
            status: postData.status,
            priority: postData.priority,
            boardId: postData.boardId,
            board_column_id: postData.board_column_id,
            reporter: postData.reporter,
            cardType: 'individual',
            description: postData.description , 
            card_name: postData.card_name,
            assignees:postData.assignees,
            due_date:postData.due_date,
            cardid: cardDetails.id,
            file_attachments:cardDetails.file_attachments.map((file:any)=> Object.values(file)[0]),
            tagIds: cardDetails?.tags?.map((tag:any)=>tag.id),
          };
      
          await cloneCard(payload, userId);

    } catch (error) {
        // console.log(error)
    }
};
